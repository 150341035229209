define('travis/components/enterprise-banner', ['exports', 'ember-decorators/object', 'ember-decorators/object/computed', 'ember-decorators/service', 'travis/utils/time-ago-in-words'], function (exports, _object, _computed, _service, _timeAgoInWords) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _desc, _value, _obj, _init, _init2, _init3;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('seats', 'activeUsers'), _dec2 = (0, _object.computed)('seats', 'activeUsers'), _dec3 = (0, _object.computed)('expirationTime'), _dec4 = (0, _object.computed)('expirationTime'), _dec5 = (0, _object.computed)('daysUntilExpiry'), _dec6 = (0, _object.computed)('daysUntilExpiry'), _dec7 = (0, _object.computed)('daysUntilExpiry'), _dec8 = (0, _object.computed)('expiring', 'expiringHalfway', 'expiringSoon'), _dec9 = (0, _computed.alias)('isTrial'), _dec10 = (0, _object.computed)('isPaid', 'checkLicenseBanner'), _dec11 = (0, _object.computed)('isPaid', 'checkSeatsBanner'), _dec12 = (0, _object.computed)('almostExceedingSeats', 'exceedingSeats'), _dec13 = (0, _object.computed)('expiresSoon'), (_obj = { ajax: null,
    storage: null,

    lsLicense: 'travis.enterprise.license_msg_last_seen',
    lsSeats: 'travis.enterprise.seats_msg_seen',

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var url = '/v3/enterprise_license';

      this.get('ajax').get(url).then(function (response) {
        var exp = new Date(Date.parse(response.expiration_time));
        _this.setProperties({
          licenseId: response.license_id,
          expirationTime: exp,
          daysUntilExpiry: Math.ceil((exp.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)),
          licenceType: response.license_type,
          seats: response.seats,
          activeUsers: response.active_users,
          isTrial: response.license_type === 'trial',
          isPaid: response.license_type !== 'trial'
        });

        // Temporary removing these until we can rework these to be more
        // emberlike and resiliant to odd timing issues
        // if (this.get('daysUntilExpiry') && !this.get('expiring')) {
        //  this.get('storage').removeItem(this.get('lsLicense'));
        // }
        // if (!this.get('almostExceedingSeats') && !this.get('exceedingSeats')) {
        //   this.get('storage').removeItem(this.get('lsSeats'));
        // }
      });
    },
    exceedingSeats: function exceedingSeats(seats, activeUsers) {
      return activeUsers > seats;
    },
    almostExceedingSeats: function almostExceedingSeats(seats, activeUsers) {
      return seats - activeUsers <= 5;
    },
    isExpired: function isExpired(expirationTime) {
      return new Date() > expirationTime;
    },
    expirationTimeFromNow: function expirationTimeFromNow(expirationTime) {
      return new Ember.String.htmlSafe((0, _timeAgoInWords.default)(expirationTime) || '-');
    },
    expiring: function expiring(days) {
      if (!days) return false;
      return days <= 60;
    },
    expiringHalfway: function expiringHalfway(days) {
      if (!days) return false;
      return days <= 30;
    },
    expiringSoon: function expiringSoon(days) {
      if (!days) return false;
      return days <= 10;
    },
    checkLicenseBanner: function checkLicenseBanner(expiring, halfway, soon) {
      var lastSeen = this.get('storage').getItem(this.get('lsLicense'));
      if (
      // User has never closed banner, and license expires in 60 days or less
      !lastSeen && expiring ||
      // User has either never closed the banner, or closed it before 30 days,
      // and license expires in 30 days or less
      (!lastSeen || lastSeen > 30) && halfway ||
      // License expires in 10 days or less
      soon) {
        return true;
      } else {
        return false;
      }
    },
    showTrialBanner: null,

    showLicenseBanner: function showLicenseBanner(isPaid, check) {
      return isPaid && check;
    },
    showSeatsBanner: function showSeatsBanner(isPaid, check) {
      return isPaid && check;
    },
    checkSeatsBanner: function checkSeatsBanner(almostExceeding, exceeding) {
      var closed = this.get('storage').getItem(this.get('lsSeats'));
      if (exceeding) {
        return true;
      } else if (almostExceeding && !closed) {
        return true;
      } else {
        return false;
      }
    },
    licenseClass: function licenseClass(expiresSoon) {
      if (expiresSoon) return 'alert';
    },


    trialClass: null,
    seatsClass: 'alert',
    paidClass: 'alert',

    actions: {
      closeLicenseBanner: function closeLicenseBanner() {
        this.get('storage').setItem(this.get('lsLicense'), this.get('daysUntilExpiry'));
        this.set('showLicenseBanner', false);
      },
      closeSeatsBanner: function closeSeatsBanner() {
        this.get('storage').setItem(this.get('lsSeats'), true);
        this.set('showSeatsBanner', false);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'ajax', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'ajax'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'storage', [_service.service], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'storage'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'exceedingSeats', [_dec], Object.getOwnPropertyDescriptor(_obj, 'exceedingSeats'), _obj), _applyDecoratedDescriptor(_obj, 'almostExceedingSeats', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'almostExceedingSeats'), _obj), _applyDecoratedDescriptor(_obj, 'isExpired', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isExpired'), _obj), _applyDecoratedDescriptor(_obj, 'expirationTimeFromNow', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'expirationTimeFromNow'), _obj), _applyDecoratedDescriptor(_obj, 'expiring', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'expiring'), _obj), _applyDecoratedDescriptor(_obj, 'expiringHalfway', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'expiringHalfway'), _obj), _applyDecoratedDescriptor(_obj, 'expiringSoon', [_dec7], Object.getOwnPropertyDescriptor(_obj, 'expiringSoon'), _obj), _applyDecoratedDescriptor(_obj, 'checkLicenseBanner', [_dec8], Object.getOwnPropertyDescriptor(_obj, 'checkLicenseBanner'), _obj), _applyDecoratedDescriptor(_obj, 'showTrialBanner', [_dec9], (_init3 = Object.getOwnPropertyDescriptor(_obj, 'showTrialBanner'), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'showLicenseBanner', [_dec10], Object.getOwnPropertyDescriptor(_obj, 'showLicenseBanner'), _obj), _applyDecoratedDescriptor(_obj, 'showSeatsBanner', [_dec11], Object.getOwnPropertyDescriptor(_obj, 'showSeatsBanner'), _obj), _applyDecoratedDescriptor(_obj, 'checkSeatsBanner', [_dec12], Object.getOwnPropertyDescriptor(_obj, 'checkSeatsBanner'), _obj), _applyDecoratedDescriptor(_obj, 'licenseClass', [_dec13], Object.getOwnPropertyDescriptor(_obj, 'licenseClass'), _obj)), _obj)));
});