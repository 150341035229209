define('travis/utils/log-folder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    function LogFolder(element) {
      this.element = element;
      var handlerSelector = '.fold .log-line:first-of-type, .fold .fold-name';
      if (this.element) {
        this.element.off('click', handlerSelector) // remove any previous click handlers
        .on('click', handlerSelector, function (_this) {
          return function (event) {
            var folder = _this.getFolderFromLine(Ember.$(event.target));
            _this.toggle(folder);
            event.preventDefault();
            return false;
          };
        }(this));
      }
    }

    LogFolder.prototype.fold = function (line) {
      var folder = void 0;
      folder = this.getFolderFromLine(line);
      if (folder.hasClass('open')) {
        return this.toggle(folder);
      }
    };

    LogFolder.prototype.unfold = function (line) {
      var folder = void 0;
      folder = this.getFolderFromLine(line);
      if (!folder.hasClass('open')) {
        return this.toggle(folder);
      }
    };

    LogFolder.prototype.toggle = function (folder) {
      return folder.toggleClass('open');
    };

    LogFolder.prototype.getFolderFromLine = function (line) {
      return line.parents('.fold').first();
    };

    return LogFolder;
  }();
});