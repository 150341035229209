define('travis/utils/tailing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    function Tailing(window1, tailSelector, logSelector) {
      var _this = this;

      this.tailSelector = tailSelector;
      this.logSelector = logSelector;
      this.position = this._scrollPosTop();
      document.addEventListener('scroll', function () {
        Ember.run.throttle(_this, _this.onScroll, [], 200, false);
      });
      return this;
    }

    Tailing.prototype.options = {
      timeout: 1000
    };

    Tailing.prototype.tail = function () {
      return document.querySelector(this.tailSelector);
    };

    Tailing.prototype.log = function () {
      return document.querySelector(this.logSelector);
    };

    Tailing.prototype.run = function () {
      this.autoScroll();
      this.positionButton();
      if (this.isActive()) {
        return Ember.run.later(this.run.bind(this), this.options.timeout);
      }
    };

    Tailing.prototype.toggle = function () {
      if (this.isActive()) {
        return this.stop();
      } else {
        return this.start();
      }
    };

    Tailing.prototype.start = function () {
      var tail = this.tail();
      if (tail) {
        tail.classList.add('active');
      }
      return this.run();
    };

    Tailing.prototype.isActive = function () {
      var tail = this.tail();
      return tail && tail.classList.contains('active');
    };

    Tailing.prototype.stop = function () {
      var tail = this.tail();
      return tail && tail.classList.remove('active');
    };

    Tailing.prototype.autoScroll = function () {
      if (!this.isActive()) {
        return false;
      }
      var log = this.log();
      var logOffset = this._offsetTop(log);
      var logHeight = log.offsetHeight;
      var logBottom = logOffset + logHeight + 40;

      var scrollPosTop = this._scrollPosTop();
      var windowHeight = window.innerHeight;
      var winBottom = scrollPosTop + windowHeight;

      var logWinDifference = logBottom - winBottom;

      if (logWinDifference > 0) {
        var newYpos = logBottom - windowHeight;
        var newXpos = this._scrollPosLeft();
        window.scrollTo(newXpos, newYpos);
        return true;
      } else {
        return false;
      }
    };

    Tailing.prototype.onScroll = function () {
      var position = void 0;
      this.positionButton();
      position = this._scrollPosTop();
      if (position < this.position) {
        this.stop();
      }
      return this.position = position;
    };

    Tailing.prototype.positionButton = function () {
      var max = void 0,
          offset = void 0;
      var tail = this.tail();
      var log = this.log();
      if (!tail) {
        //return;
      }

      offset = this._scrollPosTop();
      max = log.clientHeight - tail.clientHeight + 5;
      if (offset > max) {
        offset = max;
      }

      var newOffset = 0;
      if (offset > 0) {
        newOffset = offset;
      }
      tail.style.top = newOffset + 'px';

      return newOffset;
    };

    Tailing.prototype._offsetTop = function (el) {
      var _el$getBoundingClient = el.getBoundingClientRect(),
          top = _el$getBoundingClient.top;

      var scrollTop = this._scrollPosTop();
      return top + scrollTop;
    };

    Tailing.prototype._scrollPosTop = function () {
      return window && window.pageYOffset;
    };

    Tailing.prototype._scrollPosLeft = function () {
      return window && window.pageXOffset;
    };

    return Tailing;
  }();
});