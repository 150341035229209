define('travis/routes/dashboard/repositories', ['exports', 'travis/routes/basic', 'travis/utils/dashboard-repositories-sort', 'travis/config/environment', 'ember-decorators/object', 'ember-decorators/service'], function (exports, _basic, _dashboardRepositoriesSort, _environment, _object, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj, _init;

  exports.default = _basic.default.extend((_dec = (0, _object.computed)(), (_obj = { features: null,

    queryParams: {
      filter: {
        replace: true
      },
      page: {
        refreshModel: true
      }
    },

    redirect: function redirect() {
      if (!this.get('features.dashboard')) {
        return this.transitionTo('index');
      }
    },
    recordsPerPage: function recordsPerPage() {
      return _environment.default.pagination.dashboardReposPerPage;
    },
    model: function model(params) {
      var offset = (params.page - 1) * this.get('recordsPerPage');
      return Ember.RSVP.hash({
        starredRepos: this.store.filter('repo', {
          active: true,
          sort_by: 'current_build:desc',
          starred: true
        }, function (repo) {
          return repo.get('starred');
        }, ['starred'], true),
        repos: this.store.paginated('repo', {
          active: true,
          sort_by: 'current_build:desc',
          offset: offset,
          limit: this.get('recordsPerPage')
        }, {
          filter: function filter(repo) {
            return repo.get('active') && repo.get('isCurrentUserACollaborator');
          },
          sort: _dashboardRepositoriesSort.default,
          dependencies: ['active', 'isCurrentUserACollaborator'],
          forceReload: true
        }),
        accounts: this.store.filter('account', {
          all: true
        }, function () {
          return true;
        }, [], true)
      });
    },
    afterModel: function afterModel(model) {
      var repos = model.repos;

      // This preloads related models to prevent a backtracking rerender error.
      return Ember.RSVP.hash({
        currentBuilds: repos.map(function (repo) {
          return repo.get('currentBuild');
        }),
        defaultBranches: repos.map(function (repo) {
          return repo.get('defaultBranch');
        })
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'features', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'features'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'recordsPerPage', [_dec], Object.getOwnPropertyDescriptor(_obj, 'recordsPerPage'), _obj)), _obj)));
});