define('travis/components/header-links', ['exports', 'ember-decorators/service', 'ember-decorators/object', 'ember-decorators/object/computed', 'travis/config/environment'], function (exports, _service, _object, _computed, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj, _init, _init2, _init3, _init4;

  exports.default = Ember.Component.extend((_dec = (0, _computed.alias)('auth.currentUser'), _dec2 = (0, _object.computed)('user.{login,name}'), _dec3 = (0, _object.computed)(), _dec4 = (0, _object.computed)('tab', 'auth.state'), (_obj = {
    tagName: '',

    config: _environment.default,

    auth: null,
    features: null,
    externalLinks: null,

    user: null,

    userName: function userName(login, name) {
      return name || login;
    },
    deploymentVersion: function deploymentVersion() {
      if (window && window.location) {
        var hostname = window.location.hostname;

        if (hostname.indexOf('ember-beta') === 0 || hostname.indexOf('ember-canary') === 0) {
          return 'Ember ' + Ember.VERSION;
        } else if (hostname.indexOf('test-deployments') > 0) {
          var branchName = hostname.split('.')[0];
          var branchURL = this.get('externalLinks').travisWebBranch(branchName);
          var branchLink = '<a href=\'' + branchURL + '\'><code>' + branchName + '</code></a>';

          return Ember.String.htmlSafe('Test deployment ' + branchLink);
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    classProfile: function classProfile(tab, authState) {
      var classes = ['profile menu'];

      if (this.get('tab') === 'profile') {
        classes.push('active');
      }

      classes.push(authState || 'signed-out');

      return classes.join(' ');
    },
    helpscoutTrigger: function helpscoutTrigger() {
      HS.beacon.open();
      return false;
    },
    signIn: function signIn() {
      return this.get('auth').signIn();
    },
    signOut: function signOut() {
      return this.get('auth').signOut();
    }
  }, (_applyDecoratedDescriptor(_obj, 'auth', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'auth'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'features', [_service.service], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'features'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'externalLinks', [_service.service], (_init3 = Object.getOwnPropertyDescriptor(_obj, 'externalLinks'), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'user', [_dec], (_init4 = Object.getOwnPropertyDescriptor(_obj, 'user'), _init4 = _init4 ? _init4.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init4;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'userName', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'userName'), _obj), _applyDecoratedDescriptor(_obj, 'deploymentVersion', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'deploymentVersion'), _obj), _applyDecoratedDescriptor(_obj, 'classProfile', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'classProfile'), _obj), _applyDecoratedDescriptor(_obj, 'helpscoutTrigger', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'helpscoutTrigger'), _obj), _applyDecoratedDescriptor(_obj, 'signIn', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'signIn'), _obj), _applyDecoratedDescriptor(_obj, 'signOut', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'signOut'), _obj)), _obj)));
});