define('travis/helpers/pretty-date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.prettyDate = prettyDate;
  function prettyDate(params) {
    var date = new Date(params[0]);
    return new Ember.String.htmlSafe((0, _moment.default)(date).format('MMMM D, YYYY H:mm:ss') || '-');
  }

  exports.default = Ember.Helper.helper(prettyDate);
});