define('travis/components/header-broadcasts', ['exports', 'ember-decorators/object', 'ember-decorators/object/computed', 'ember-decorators/service'], function (exports, _object, _computed, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj, _init, _init2, _init3, _init4;

  exports.default = Ember.Component.extend((_dec = (0, _service.service)('broadcasts'), _dec2 = (0, _computed.alias)('broadcastsService.broadcasts'), (_obj = { auth: null,
    features: null,
    broadcastsService: null,

    broadcasts: null,

    toggleBroadcasts: function toggleBroadcasts() {
      this.toggleProperty('showBroadcasts');
      return false;
    },
    markBroadcastAsSeen: function markBroadcastAsSeen(broadcast) {
      this.get('broadcastsService').markAsSeen(broadcast);
      return false;
    }
  }, (_applyDecoratedDescriptor(_obj, 'auth', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'auth'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'features', [_service.service], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'features'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'broadcastsService', [_dec], (_init3 = Object.getOwnPropertyDescriptor(_obj, 'broadcastsService'), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'broadcasts', [_dec2], (_init4 = Object.getOwnPropertyDescriptor(_obj, 'broadcasts'), _init4 = _init4 ? _init4.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init4;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'toggleBroadcasts', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'toggleBroadcasts'), _obj), _applyDecoratedDescriptor(_obj, 'markBroadcastAsSeen', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'markBroadcastAsSeen'), _obj)), _obj)));
});