define('travis/utils/api-errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = hasErrorWithStatus;
  /**
   * Check if the status error occurs in the response errors collection
   *
   */
  function hasErrorWithStatus(errorResponse, status) {
    var _ref = errorResponse || {},
        _ref$errors = _ref.errors,
        errors = _ref$errors === undefined ? [] : _ref$errors;

    return errors.isAny('status', status);
  }
});