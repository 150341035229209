define('travis/utils/pusher', ['exports', 'npm:pusher-js'], function (exports, _npmPusherJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TravisPusher = function TravisPusher(config, ajaxService) {
    this.active_channels = [];
    this.init(config, ajaxService);
    TravisPusher.ajaxService = ajaxService;
    return this;
  };

  TravisPusher.prototype.init = function (config, ajaxService) {
    if (!config.key) {
      // Set up a mock Pusher that ignores the expected methods.
      return this.pusher = {
        subscribe: function subscribe() {
          return {
            bind_global: function bind_global() {}
          };
        },
        channel: function channel() {}
      };
    }

    this.ajaxService = ajaxService;
    _npmPusherJs.default.warn = this.warn.bind(this);

    if (config.debug) {
      _npmPusherJs.default.log = function (message) {
        if (window.console && window.console.log) {
          window.console.log(message);
        }
      };
    }

    var pusherConfig = {
      encrypted: config.encrypted,
      disableStats: true,
      wsHost: config.host,

      authorizer: function authorizer(channel, options) {
        return {
          authorize: function authorize(socketId, callback) {
            var channelName = channel.name;

            TravisPusher.ajaxService.post('/pusher/auth', {
              socket_id: socketId,
              channels: [channelName]
            }).then(function (data) {
              callback(false, { auth: data['channels'][channelName] });
            });
          }
        };
      }
    };

    if (config.path) {
      pusherConfig.wsPath = '/' + config.path;
    }

    return this.pusher = new _npmPusherJs.default(config.key, pusherConfig);
  };

  TravisPusher.prototype.subscribeAll = function (channels) {
    var channel = void 0,
        i = void 0,
        len = void 0,
        results = void 0;
    results = [];
    for (i = 0, len = channels.length; i < len; i++) {
      channel = channels[i];
      results.push(this.subscribe(channel));
    }
    return results;
  };

  TravisPusher.prototype.unsubscribeAll = function (channels) {
    var channel = void 0,
        i = void 0,
        len = void 0,
        results = void 0;
    results = [];
    for (i = 0, len = channels.length; i < len; i++) {
      channel = channels[i];
      results.push(this.unsubscribe(channel));
    }
    return results;
  };

  TravisPusher.prototype.subscribe = function (channelName) {
    var _this = this;

    if (channelName && this.pusher && !this.pusher.channel(channelName)) {
      this.active_channels.push(channelName);
      return this.pusher.subscribe(channelName).bind_global(function (event, data) {
        _this.receive(event, data);
      });
    }
  };

  TravisPusher.prototype.unsubscribe = function (channelName) {
    if (channelName && this.pusher) {
      this.active_channels.removeObject(channelName);
      return this.pusher.unsubscribe(channelName);
    }
  };

  TravisPusher.prototype.receive = function (event, data) {
    var _this2 = this;

    if (event.substr(0, 6) === 'pusher') {
      return;
    }
    if (data.id) {
      data = this.normalize(event, data);
    }

    // TODO remove job:requeued, once sf-restart-event has been merged
    // TODO this also needs to clear logs on build:created if matrix jobs are already loaded
    if (event === 'job:created' || event === 'job:requeued') {
      var job = this.store.peekRecord('job', data.job.id);
      if (job) {
        job.clearLog();
      }
    }

    Ember.run.next(function () {
      return _this2.pusherService.receive(event, data);
    });
  };

  TravisPusher.prototype.normalize = function (event, data) {
    switch (event) {
      case 'build:started':
      case 'build:finished':
        return data;
      case 'job:created':
      case 'job:queued':
      case 'job:received':
      case 'job:started':
      case 'job:requeued':
      case 'job:finished':
      case 'job:log':
      case 'job:canceled':
        if (data.queue) {
          data.queue = data.queue.replace('builds.', '');
        }
        return {
          job: data,
          _no_full_payload: data._no_full_payload
        };
      case 'worker:added':
      case 'worker:updated':
      case 'worker:removed':
        return {
          worker: data
        };
      case 'annotation:created':
      case 'annotation:updated':
        return {
          annotation: data
        };
    }
  };

  TravisPusher.prototype.warn = function (type, object) {
    if (!this.ignoreWarning(type, object.error)) {
      // eslint-disable-next-line
      return console.warn(type, object.error);
    }
  };

  TravisPusher.prototype.ignoreWarning = function (type, error) {
    var code = void 0,
        message = void 0,
        ref = void 0,
        ref1 = void 0;
    code = (error != null ? (ref = error.data) != null ? ref.code : void 0 : void 0) || 0;
    message = (error != null ? (ref1 = error.data) != null ? ref1.message : void 0 : void 0) || '';
    return this.ignoreCode(code) || this.ignoreMessage(message);
  };

  TravisPusher.prototype.ignoreCode = function (code) {
    return code === 1006;
  };

  TravisPusher.prototype.ignoreMessage = function (message) {
    var existingSubscription = message.indexOf('Existing subscription') === 0;
    var noSubscription = message.indexOf('No current subscription') === 0;
    return existingSubscription || noSubscription;
  };

  exports.default = TravisPusher;
});