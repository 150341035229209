define('travis/components/show-more-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['showmore-button', 'button'],
    label: 'Show more'
  });
});