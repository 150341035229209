define('travis/components/build-messages-list', ['exports', 'ember-decorators/object', 'ember-decorators/service'], function (exports, _object, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj, _init, _init2;

  var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('repo.id', 'build.request.id'), (_obj = { ajax: null,
    store: null,

    messagesRequest: function messagesRequest(repoId, requestId) {
      if (requestId) {
        return ObjectPromiseProxy.create({
          promise: this.get('ajax').ajax('/repo/' + repoId + '/request/' + requestId + '/messages', 'get', {
            headers: {
              'Travis-API-Version': '3'
            } }).then(function (response) {
            return { messages: response.messages };
          })
        });
      } else {
        return ObjectPromiseProxy.create({
          promise: Ember.RSVP.Promise.resolve([])
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'ajax', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'ajax'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'store', [_service.service], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'store'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'messagesRequest', [_dec], Object.getOwnPropertyDescriptor(_obj, 'messagesRequest'), _obj)), _obj)));
});