define('travis/instance-initializers/pro-environment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var config = appInstance.resolveRegistration('config:environment');

    var featureFlags = config.featureFlags;


    if (featureFlags['pro-version']) {
      featureFlags['repository-filtering'] = true;
      featureFlags['debug-logging'] = false;
      featureFlags['landing-page-cta'] = false;
      featureFlags['show-running-jobs-in-sidebar'] = true;
      featureFlags['debug-builds'] = true;
      featureFlags['broadcasts'] = true;
      featureFlags['beta-features'] = true;
    }
  }

  exports.default = {
    name: 'pro-environment',
    initialize: initialize
  };
});