define('travis/services/job-config-fetcher', ['exports', 'ember-decorators/service'], function (exports, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj, _init;

  exports.default = Ember.Service.extend((_obj = { store: null,

    init: function init() {
      this.toFetch = {};
      return this._super.apply(this, arguments);
    },
    fetch: function fetch(job) {
      if (job.get('_config')) {
        return Ember.RSVP.Promise.resolve(job.get('_config'));
      }
      var jobId = job.get('id');
      if (this.toFetch[jobId]) {
        return this.toFetch[jobId].promise;
      } else {
        var data = this.toFetch[jobId] = { job: job };
        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          data.resolve = resolve;
        });
        data.promise = promise;
        Ember.run.once(this, 'flush');

        var PromiseObject = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
        return PromiseObject.create({ promise: promise });
      }
    },
    flush: function flush() {
      var _this = this;

      var toFetch = this.toFetch;
      this.toFetch = {};
      var buildIds = new Set();
      Object.values(toFetch).forEach(function (data) {
        return buildIds.add(data.job.get('build.id'));
      });

      buildIds.forEach(function (id) {
        var queryParams = { id: id, include: 'job.config,build.jobs' };
        _this.get('store').queryRecord('build', queryParams).then(function (build) {
          build.get('jobs').forEach(function (job) {
            var data = toFetch[job.get('id')];
            if (data) {
              data.resolve(job.get('_config'));
            }
          });
        });
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'store', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'store'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj)), _obj));
});