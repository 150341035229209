define('travis/utils/init-hs-beacon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = initHsBeacon;
  /* eslint-disable */
  function initHsBeacon() {
    !function (e, o, n) {
      window.HSCW = o, window.HS = n, n.beacon = n.beacon || {};var t = n.beacon;t.userConfig = {}, t.readyQueue = [], t.config = function (e) {
        this.userConfig = e;
      }, t.ready = function (e) {
        this.readyQueue.push(e);
      }, o.config = { docs: { enabled: !1, baseUrl: '' }, contact: { enabled: !0, formId: 'f48f821c-fb20-11e5-a329-0ee2467769ff' } };var r = e.getElementsByTagName('script')[0],
          c = e.createElement('script');c.type = 'text/javascript', c.async = !0, c.src = 'https://djtflbt20bdde.cloudfront.net/', r.parentNode.insertBefore(c, r);
    }(document, window.HSCW || {}, window.HS || {});

    HS.beacon.config({
      modal: true,
      attachment: true,
      instructions: 'Please make sure to include a link if your question involves a specific repo, build or job.',
      showSubject: true
    });
  }
});