define('travis/services/api', ['exports', 'travis/config/environment', 'ember-decorators/service'], function (exports, _environment, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj, _init, _init2;

  exports.default = Ember.Service.extend((_obj = { auth: null,
    features: null,

    get: function get(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.request(url, 'GET', options);
    },
    post: function post(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.request(url, 'POST', options);
    },
    patch: function patch(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.request(url, 'PATCH', options);
    },
    put: function put(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.request(url, 'PUT', options);
    },
    delete: function _delete(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.request(url, 'DELETE', options);
    },
    request: function request(url) {
      var _this = this;

      var method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'GET';
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var endpoint = _environment.default.apiEndpoint || '';
      var token = Ember.get(this, 'auth.token');

      options.headers = options.headers || {};
      options.headers['Travis-API-Version'] = '3';
      if (!options.headers['Accept']) {
        options.headers['Accept'] = 'application/json';
      }

      if (token) {
        if (!options.headers['Authorization']) {
          options.headers['Authorization'] = 'token ' + token;
        }
      }
      options.url = url = '' + endpoint + url;
      options.method = method;
      options.dataType = options.dataType || 'json';
      options.contentType = 'application/json';

      if (options.data) {
        options.data = JSON.stringify(options.data);
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        options.error = function (jqXHR, textStatus, errorThrown) {
          if (Ember.get(_this, 'features.debugLogging')) {
            // eslint-disable-next-line
            console.log('[ERROR] API responded with an error (' + status + '): ' + JSON.stringify(data));
          }
          Ember.run(function () {
            // TODO: in the future we might want to run some handler here
            // that would process all args
            reject(jqXHR);
          });
        };

        options.success = function (payload, textStatus, jqXHR) {
          Ember.run(function () {
            resolve(payload);
          });
        };

        Ember.$.ajax(url, options);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'auth', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'auth'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'features', [_service.service], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'features'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj)), _obj));
});