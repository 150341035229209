define('travis/components/job-infrastructure-notification', ['exports', 'ember-decorators/object', 'ember-decorators/object/computed', 'ember-decorators/service', 'moment'], function (exports, _object, _computed, _service, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _desc, _value, _obj, _init, _init2, _init3, _init4, _init5, _init6, _init7, _init8;

  var NOVEMBER_2017_RETIREMENT = '2017-11-28T12:00:00-08:00';
  var LATEST_TRUSTY_RELEASE = '2017-12-12T17:25:00-00:00';

  exports.default = Ember.Component.extend((_dec = (0, _computed.alias)('job.queue'), _dec2 = (0, _computed.alias)('job.config'), _dec3 = (0, _object.computed)('job.isFinished'), _dec4 = (0, _computed.equal)('queue', 'builds.linux'), _dec5 = (0, _computed.equal)('queue', 'builds.ec2'), _dec6 = (0, _object.computed)('job.startedAt', 'queue', 'job.config.dist'), _dec7 = (0, _computed.equal)('queue', 'builds.macstadium6'), _dec8 = (0, _object.computed)('queue', 'job.config.dist', 'job.config.language'), _dec9 = (0, _computed.alias)('jobConfig.osx_image'), _dec10 = (0, _object.computed)('isMacStadium6', 'macOSImage'), _dec11 = (0, _object.computed)('job.startedAt', 'macOSImage', 'job.isFinished', 'conjugatedRun', 'isDeprecatedOrRetiredMacImage'), (_obj = { auth: null,
    features: null,

    queue: null,
    jobConfig: null,

    conjugatedRun: function conjugatedRun(isFinished) {
      return isFinished ? 'ran' : 'is running';
    },
    isLegacyInfrastructure: null,
    isTrustySudoFalse: null,

    isTrustySudoRequired: function isTrustySudoRequired(startedAt, queue, dist) {
      if (queue === 'builds.gce' && dist === 'trusty') {
        var jobRanAfterReleaseDate = Date.parse(startedAt) > Date.parse(LATEST_TRUSTY_RELEASE);
        if (jobRanAfterReleaseDate) {
          return true;
        }
      }

      return false;
    },
    isMacStadium6: null,

    isPreciseEOL: function isPreciseEOL(queue, dist, language) {
      if (queue === 'builds.gce' && dist === 'precise') {
        if (language !== 'android') {
          return true;
        }
      }
    },
    macOSImage: null,

    deprecatedXcodeImages: ['xcode8.1', 'xcode8.2'],

    imageToRetirementDate: {
      'xcode8.1': NOVEMBER_2017_RETIREMENT,
      'xcode8.2': NOVEMBER_2017_RETIREMENT
    },

    imageToNewImage: {
      'xcode8.1': 'xcode8.3',
      'xcode8.2': 'xcode8.3'
    },

    newImageStrings: {
      'xcode8.3': 'Xcode 8.3',
      'xcode7.3': 'Xcode 7.3.1',
      'xcode6.4': 'Xcode 6.4'
    },

    isDeprecatedOrRetiredMacImage: function isDeprecatedOrRetiredMacImage(isMacStadium6, macOSImage) {
      return isMacStadium6 && this.get('deprecatedXcodeImages').includes(macOSImage);
    },
    deprecatedOrRetiredMacImageMessage: function deprecatedOrRetiredMacImageMessage(startedAt, image, isFinished, conjugatedRun) {
      var retirementDate = Date.parse(this.get('imageToRetirementDate')[image]);

      var newImage = this.get('imageToNewImage')[image];
      var newImageString = this.get('newImageStrings')[newImage];
      var newImageAnchor = newImageString.replace(' ', '-');
      var newImageURLString = '<a href=\'https://docs.travis-ci.com/user/reference/osx/#' + newImageAnchor + '\'>' + newImageString + '</a>';
      var imageRetirementAnnouncementURL = 'https://blog.travis-ci.com/2017-11-21-xcode8-3-default-image-announce';

      var jobRanBeforeRetirementDate = Date.parse(startedAt) < retirementDate;
      var retirementDateIsInTheFuture = retirementDate > new Date();

      var formattedRetirementDate = (0, _moment.default)(retirementDate).format('MMMM D, YYYY');

      var retirementLink = '<a href=\'' + imageRetirementAnnouncementURL + '\'>\n      ' + (retirementDateIsInTheFuture ? 'will be retired' : 'was retired') + '\n      on ' + formattedRetirementDate + '</a>';

      var retirementSentence = void 0,
          routingSentence = void 0;

      if (retirementDateIsInTheFuture) {
        retirementSentence = 'This job ' + conjugatedRun + ' on an OS X image that ' + retirementLink + '.';
      } else {
        retirementSentence = '\n        This job ' + (isFinished ? 'was configured to run on' : 'is configured to run on') + '\n        an OS X image that ' + retirementLink + '.';
      }

      if (retirementDateIsInTheFuture) {
        routingSentence = 'After that, it will route to our ' + newImageURLString + ' image.';
      } else if (jobRanBeforeRetirementDate) {
        routingSentence = 'New jobs will route to our ' + newImageURLString + ' image.';
      } else {
        routingSentence = 'It was routed to our ' + newImageURLString + ' image.';
      }

      return retirementSentence + ' ' + routingSentence;
    }
  }, (_applyDecoratedDescriptor(_obj, 'auth', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'auth'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'features', [_service.service], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'features'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'queue', [_dec], (_init3 = Object.getOwnPropertyDescriptor(_obj, 'queue'), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'jobConfig', [_dec2], (_init4 = Object.getOwnPropertyDescriptor(_obj, 'jobConfig'), _init4 = _init4 ? _init4.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init4;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'conjugatedRun', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'conjugatedRun'), _obj), _applyDecoratedDescriptor(_obj, 'isLegacyInfrastructure', [_dec4], (_init5 = Object.getOwnPropertyDescriptor(_obj, 'isLegacyInfrastructure'), _init5 = _init5 ? _init5.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init5;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'isTrustySudoFalse', [_dec5], (_init6 = Object.getOwnPropertyDescriptor(_obj, 'isTrustySudoFalse'), _init6 = _init6 ? _init6.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init6;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'isTrustySudoRequired', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'isTrustySudoRequired'), _obj), _applyDecoratedDescriptor(_obj, 'isMacStadium6', [_dec7], (_init7 = Object.getOwnPropertyDescriptor(_obj, 'isMacStadium6'), _init7 = _init7 ? _init7.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init7;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'isPreciseEOL', [_dec8], Object.getOwnPropertyDescriptor(_obj, 'isPreciseEOL'), _obj), _applyDecoratedDescriptor(_obj, 'macOSImage', [_dec9], (_init8 = Object.getOwnPropertyDescriptor(_obj, 'macOSImage'), _init8 = _init8 ? _init8.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init8;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'isDeprecatedOrRetiredMacImage', [_dec10], Object.getOwnPropertyDescriptor(_obj, 'isDeprecatedOrRetiredMacImage'), _obj), _applyDecoratedDescriptor(_obj, 'deprecatedOrRetiredMacImageMessage', [_dec11], Object.getOwnPropertyDescriptor(_obj, 'deprecatedOrRetiredMacImageMessage'), _obj)), _obj)));
});