define('travis/components/top-bar', ['exports', 'ember-decorators/object', 'ember-decorators/object/computed', 'ember-decorators/service', 'ember-in-viewport'], function (exports, _object, _computed, _service, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj, _init, _init2, _init3, _init4, _init5, _init6;

  exports.default = Ember.Component.extend(_emberInViewport.default, (_dec = (0, _computed.alias)('auth.currentUser'), _dec2 = (0, _object.computed)('user.{login,name}'), _dec3 = (0, _object.computed)('auth.signedIn', 'landingPage', 'features.landingPageCta'), (_obj = { auth: null,
    store: null,
    externalLinks: null,
    features: null,
    flashes: null,

    tagName: 'header',
    classNames: ['top'],
    landingPage: false,

    user: null,

    userName: function userName(login, name) {
      return name || login;
    },
    showCta: function showCta(signedIn, landingPage, ctaEnabled) {
      return !signedIn && !landingPage && ctaEnabled;
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (Ember.testing) {
        return;
      }

      Ember.setProperties(this, {
        viewportSpy: true
      });
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.set(_this, 'viewportTolerance.top', _this.$().height());
      });
    },
    didEnterViewport: function didEnterViewport() {
      this.get('flashes').set('topBarVisible', true);
    },
    didExitViewport: function didExitViewport() {
      this.get('flashes').set('topBarVisible', false);
    }
  }, (_applyDecoratedDescriptor(_obj, 'auth', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'auth'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'store', [_service.service], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'store'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'externalLinks', [_service.service], (_init3 = Object.getOwnPropertyDescriptor(_obj, 'externalLinks'), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'features', [_service.service], (_init4 = Object.getOwnPropertyDescriptor(_obj, 'features'), _init4 = _init4 ? _init4.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init4;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'flashes', [_service.service], (_init5 = Object.getOwnPropertyDescriptor(_obj, 'flashes'), _init5 = _init5 ? _init5.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init5;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'user', [_dec], (_init6 = Object.getOwnPropertyDescriptor(_obj, 'user'), _init6 = _init6 ? _init6.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init6;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'userName', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'userName'), _obj), _applyDecoratedDescriptor(_obj, 'showCta', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'showCta'), _obj)), _obj)));
});