define('travis/utils/fuzzy-match', ['exports', 'npm:fuzzysort'], function (exports, _npmFuzzysort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = fuzzyMatch;
  function fuzzyMatch(string, query) {
    var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '<b>';
    var suffix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '</b>';

    return _npmFuzzysort.default.highlight(_npmFuzzysort.default.single(query, string), prefix, suffix) || string;
  }
});