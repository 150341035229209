define('travis/components/log-content', ['exports', 'travis/utils/lines-selector', 'travis/utils/log', 'travis/utils/log-folder', 'travis/config/environment'], function (exports, _linesSelector, _log, _logFolder, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  _log.default.LIMIT = _environment.default.logLimit; /* global Travis */


  _log.default.Scroll = function () {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    this.beforeScroll = options.beforeScroll;
    return this;
  };

  _log.default.Scroll.prototype = Ember.$.extend(new _log.default.Listener(), {
    insert: function insert() {
      if (this.numbers) {
        this.tryScroll();
      }
      return true;
    },
    tryScroll: function tryScroll() {
      var ref = void 0;
      var element = Ember.$('#log .log-line:visible.highlight:first');
      if (element) {
        if (this.beforeScroll) {
          this.beforeScroll();
        }
        Ember.$('#main').scrollTop(0);
        var offset = element.offset();
        var scrollTop = ((ref = offset) != null ? ref.top : void 0) - window.innerHeight / 3;
        if (isNaN(scrollTop)) {
          return;
        }
        return Ember.$('html, body').scrollTop(scrollTop);
      }
    }
  });

  _log.default.Limit = function (maxLines, limitedLogCallback) {
    this.maxLines = maxLines || 1000;
    this.limitedLogCallback = limitedLogCallback || function () {};
    return this;
  };

  _log.default.Limit.prototype = _log.default.extend(new _log.default.Listener(), {
    count: 0,
    insert: function insert(log, node) {
      if (node.type === 'paragraph' && !node.hidden) {
        this.count += 1;
        if (this.limited) {
          this.limitedLogCallback();
        }
        return this.count;
      }
    }
  });

  Object.defineProperty(_log.default.Limit.prototype, 'limited', {
    get: function get() {
      return this.count >= this.maxLines;
    }
  });

  exports.default = Ember.Component.extend({
    auth: Ember.inject.service(),
    permissions: Ember.inject.service(),
    externalLinks: Ember.inject.service(),
    router: Ember.inject.service(),

    classNameBindings: ['logIsVisible:is-open'],
    logIsVisible: false,

    currentUser: Ember.computed.alias('auth.currentUser'),

    isShowingRemoveLogModal: false,

    didInsertElement: function didInsertElement() {
      if (this.get('features.debugLogging')) {
        // eslint-disable-next-line
        console.log('log view: did insert');
      }
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, 'createEngine');
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('features.debugLogging')) {
        // eslint-disable-next-line
        console.log('log view: will destroy');
      }
      Ember.run.scheduleOnce('afterRender', this, 'teardownLog');
    },
    teardownLog: function teardownLog(log) {
      var parts = void 0,
          ref = void 0;
      if (log || (log = this.get('log'))) {
        parts = log.get('parts');
        parts.removeArrayObserver(this, {
          didChange: 'partsDidChange',
          willChange: 'noop'
        });
        parts.destroy();
        log.notifyPropertyChange('parts');
        if ((ref = this.lineSelector) != null) {
          ref.willDestroy();
        }
        this.clearLogElement();
      }
    },
    clearLogElement: function clearLogElement() {
      var logElement = this.$('#log');
      if (logElement && logElement[0]) {
        logElement[0].innerHTML = '';
      }
    },
    createEngine: function createEngine(log) {
      var _this = this;

      if (log || (log = this.get('log'))) {
        this.set('limited', false);
        this.clearLogElement();
        log.onClear(function () {
          _this.teardownLog();
          return _this.createEngine();
        });
        this.scroll = new _log.default.Scroll({
          beforeScroll: function beforeScroll() {
            _this.unfoldHighlight();
          }
        });
        this.limit = new _log.default.Limit(_log.default.LIMIT, function () {
          Ember.run(function () {
            if (!_this.isDestroying) {
              _this.set('limited', true);
            }
          });
        });
        this.engine = _log.default.create({
          listeners: [this.scroll, this.limit]
        });
        this.engine.limit = this.limit;
        this.logFolder = new _logFolder.default(this.$('#log'));
        var onLogLineClick = function onLogLineClick() {
          var router = _this.get('router'),
              currentRouteName = router.get('currentRouteName');
          if (currentRouteName === 'build.index' || currentRouteName === 'job.index') {
            return Ember.RSVP.Promise.resolve();
          } else {
            return router.transitionTo('job', log.get('job.repo'), log.get('job'));
          }
        };
        this.lineSelector = new _linesSelector.default(this.$('#log'), this.scroll, this.logFolder, null, onLogLineClick);
        this.observeParts(log);
      }
    },
    unfoldHighlight: function unfoldHighlight() {
      return this.lineSelector.unfoldLines();
    },
    observeParts: function observeParts(log) {
      var parts = void 0;
      if (log || (log = this.get('log'))) {
        parts = log.get('parts');
        parts.addArrayObserver(this, {
          didChange: 'partsDidChange',
          willChange: 'noop'
        });
        parts = parts.slice(0);
        this.partsDidChange(parts, 0, null, parts.length);
      }
    },
    partsDidChange: function partsDidChange(parts, start, _, added) {
      Ember.run.schedule('afterRender', this, function () {
        var i = void 0,
            j = void 0,
            len = void 0,
            part = void 0,
            ref = void 0,
            ref1 = void 0,
            ref2 = void 0,
            results = void 0;
        if (this.get('features.debugLogging')) {
          // eslint-disable-next-line
          console.log('log view: parts did change');
        }
        if (this.get('_state') !== 'inDOM') {
          return;
        }
        ref = parts.slice(start, start + added);
        results = [];
        for (i = j = 0, len = ref.length; j < len; i = ++j) {
          part = ref[i];
          // My brain can't process this right now.
          // eslint-disable-next-line
          if ((ref1 = this.engine) != null ? (ref2 = ref1.limit) != null ? ref2.limited : void 0 : void 0) {
            break;
          }
          results.push(this.engine.set(part.number, part.content));
        }
        return results;
      });
    },


    plainTextLogUrl: Ember.computed('log.plainTextUrl', function () {
      var url = this.get('log.plainTextUrl');
      return '' + _environment.default.apiEndpoint + url;
    }),

    hasPermission: Ember.computed('permissions.all', 'job.repo', function () {
      var repo = this.get('job.repo');
      return this.get('permissions').hasPermission(repo);
    }),

    canRemoveLog: Ember.computed('job', 'job.canRemoveLog', 'hasPermission', function () {
      var job = this.get('job');
      var canRemoveLog = this.get('job.canRemoveLog');
      var hasPermission = this.get('hasPermission');
      if (job) {
        return canRemoveLog && hasPermission;
      }
    }),

    showToTop: Ember.computed.and('log.hasContent', 'job.canRemoveLog'),

    showTailing: Ember.computed.alias('showToTop'),

    actions: {
      toTop: function toTop() {
        Travis.tailing.stop();
        return Ember.$(window).scrollTop(0);
      },
      toggleTailing: function toggleTailing() {
        Travis.tailing.toggle();
        this.engine.autoCloseFold = !Travis.tailing.isActive();
        return false;
      },
      toggleLog: function toggleLog() {
        this.toggleProperty('logIsVisible');
      },
      toggleRemoveLogModal: function toggleRemoveLogModal() {
        this.toggleProperty('isShowingRemoveLogModal');
      }
    },

    // don't remove this, it's needed as an empty willChange callback
    noop: function noop() {}
  });
});