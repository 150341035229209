define('travis/components/build-message', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var escape = Ember.Handlebars.Utils.escapeExpression;
  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('message.code', 'message.args'), _dec2 = (0, _object.computed)('message.level'), _dec3 = (0, _object.computed)('message.level'), (_obj = {
    readableMessage: function readableMessage(code, args) {
      if (this[code]) {
        return Ember.String.htmlSafe(this[code](args));
      } else {
        return Ember.String.htmlSafe('unrecognised message code <code>' + escape(code) + '</code>');
      }
    },
    alert: function alert() {
      return 'using a plain string as a secure';
    },
    alias: function alias(args) {
      return '<code>' + escape(args.alias) + '</code> is an alias for <code>' + escape(args.actual) + '</code>, using <code>' + escape(args.actual) + '</code>';
    },
    cast: function cast(args) {
      return 'casting value <code>' + escape(args.given_value) + '</code> (<code>' + escape(args.given_type) + '</code>) to <code>' + escape(args.value) + '</code> (<code>' + escape(args.type) + '</code>)';
    },
    default: function _default(args) {
      return 'missing <code>' + escape(args.key) + '</code>, defaulting to: <code>' + escape(args.default) + '</code>';
    },
    deprecated: function deprecated(args) {
      return '<code>' + escape(args.key) + '</code> is deprecated';
    },
    downcase: function downcase(args) {
      return 'downcasing <code>' + escape(args.value) + '</code>';
    },
    edge: function edge(args) {
      return '<code>' + escape(args.given) + '</code> is experimental and might be removed in the future';
    },
    flagged: function flagged(args) {
      return 'your repository must be feature flagged for <code>' + escape(args.given) + '</code> to be used';
    },
    irrelevant: function irrelevant(args) {
      return 'specified <code>' + escape(args.key) + '</code>, but this setting is not relevant for the <code>' + escape(args.on_key) + '</code> <code>' + escape(args.on_value) + '</code>';
    },
    unsupported: function unsupported(args) {
      return '<code>' + escape(args.key) + '</code> (<code>' + escape(args.value) + '</code>) is not supported on the <code>' + escape(args.on_key) + '</code> <code>' + escape(args.on_value) + '</code>';
    },
    required: function required(args) {
      return 'missing required key <code>' + escape(args.key) + '</code>';
    },
    empty: function empty(args) {
      return 'dropping empty section <code>' + escape(args.key) + '</code>';
    },
    find_key: function find_key(args) {
      return 'key <code>' + escape(args.original) + '</code> is not known, but <code>' + escape(args.key) + '</code> is, using <code>' + escape(args.key) + '</code>';
    },
    find_value: function find_value(args) {
      return 'value <code>' + escape(args.original) + '</code> is not known, but <code>' + escape(args.value) + '</code> is, using <code>' + escape(args.value) + '</code>';
    },
    clean_key: function clean_key(args) {
      return 'key <code>' + escape(args.original) + '</code> contains special characters, using <code>' + escape(args.key) + '</code>';
    },
    clean_value: function clean_value(args) {
      return 'value <code>' + escape(args.original) + '</code> is not known, but <code>' + escape(args.value) + '</code> is, using <code>' + escape(args.value) + '</code>';
    },
    underscore_key: function underscore_key(args) {
      return 'key <code>' + escape(args.original) + '</code> is camelcased, using <code>' + escape(args.key) + '</code>';
    },
    migrate: function migrate(args) {
      return 'migrating <code>' + escape(args.key) + '</code> to <code>' + escape(args.to) + '</code> (value: <code>' + escape(args.value) + '</code>)';
    },
    misplaced_key: function misplaced_key(args) {
      return 'dropping misplaced key <code>' + escape(args.key) + '</code> (<code>' + escape(args.value) + '</code>)';
    },
    unknown_key: function unknown_key(args) {
      return 'dropping unknown key <code>' + escape(args.key) + '</code> (<code>' + escape(args.value) + '</code>)';
    },
    unknown_value: function unknown_value(args) {
      return 'dropping unknown value: <code>' + escape(args.value) + '</code>';
    },
    unknown_default: function unknown_default(args) {
      return 'dropping unknown value: <code>' + escape(args.value) + '</code>, defaulting to: <code>' + escape(args.default) + '</code>';
    },
    unknown_var: function unknown_var(args) {
      return 'unknown template variable <code>' + escape(args.var) + '</code>';
    },
    invalid_key: function invalid_key(args) {
      return '<code>' + escape(args.key) + '</code> is not a valid key';
    },
    invalid_type: function invalid_type(args) {
      return 'dropping unexpected <code>' + escape(args.actual) + '</code>, expected <code>' + escape(args.expected) + '</code> (<code>' + escape(args.value) + '</code>)';
    },
    invalid_format: function invalid_format(args) {
      return 'dropping invalid format: <code>' + escape(args.value) + '</code>';
    },
    invalid_seq: function invalid_seq(args) {
      return 'unexpected sequence, using the first value (<code>' + escape(args.value) + '</code>)';
    },
    invalid_cond: function invalid_cond(args) {
      return 'unable to parse condition (<code>' + escape(args.value) + '</code>)';
    },
    iconClass: function iconClass(level) {
      return 'icon icon-' + level;
    },
    tooltipText: function tooltipText(level) {
      return {
        info: 'information',
        warn: 'warning',
        error: 'error'
      }[level];
    }
  }, (_applyDecoratedDescriptor(_obj, 'readableMessage', [_dec], Object.getOwnPropertyDescriptor(_obj, 'readableMessage'), _obj), _applyDecoratedDescriptor(_obj, 'iconClass', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'iconClass'), _obj), _applyDecoratedDescriptor(_obj, 'tooltipText', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'tooltipText'), _obj)), _obj)));
});