define('travis/serializers/cron', ['exports', 'travis/serializers/v3'], function (exports, _v) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Serializer = _v.default.extend({});

  exports.default = Serializer;
});