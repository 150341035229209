define('travis/components/lastbuild-tile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'li',
    classNameBindings: ['build.state']

  });
});