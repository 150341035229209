define('travis/services/tab-states', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    sidebarTab: 'owned',
    mainTab: 'current'
  });
});