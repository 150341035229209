define('travis/controllers/account/repositories', ['exports', 'ember-decorators/object', 'ember-decorators/service'], function (exports, _object, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj, _init;

  exports.default = Ember.Controller.extend((_dec = (0, _object.computed)('model'), (_obj = { features: null,

    page: 1,

    sortedRepositories: function sortedRepositories(repos) {
      return repos.sortBy('name');
    },
    filterQuery: function filterQuery(query) {
      return this.get('store').query('repo', {
        slug_filter: query,
        sort_by: 'slug_filter:desc',
        limit: 10,
        custom: {
          owner: this.get('login'),
          type: 'byOwner'
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'features', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'features'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'sortedRepositories', [_dec], Object.getOwnPropertyDescriptor(_obj, 'sortedRepositories'), _obj), _applyDecoratedDescriptor(_obj, 'filterQuery', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'filterQuery'), _obj)), _obj)));
});