define('travis/components/jobs-item', ['exports', 'ember-decorators/object', 'travis/utils/job-config-language'], function (exports, _object, _jobConfigLanguage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('job.config.content'), _dec2 = (0, _object.computed)('job.config.content.{env,gemfile}'), _dec3 = (0, _object.computed)('job.config.content.os'), _dec4 = (0, _object.computed)('os'), (_obj = {
    tagName: 'li',
    classNameBindings: ['job.state'],
    classNames: ['jobs-item'],

    languages: function languages(config) {
      return (0, _jobConfigLanguage.default)(config);
    },
    environment: function environment(env, gemfile) {
      if (env) {
        return env;
      } else if (gemfile) {
        return 'Gemfile: ' + gemfile;
      }
    },
    os: function os(_os) {
      if (_os === 'linux' || _os === 'linux-ppc64le') {
        return 'linux';
      } else if (_os === 'osx') {
        return 'osx';
      } else {
        return 'unknown';
      }
    },
    osIcon: function osIcon(os) {
      if (os === 'linux') {
        return 'icon-linux';
      } else if (os === 'osx') {
        return 'icon-mac';
      } else {
        return 'help';
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'languages', [_dec], Object.getOwnPropertyDescriptor(_obj, 'languages'), _obj), _applyDecoratedDescriptor(_obj, 'environment', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'environment'), _obj), _applyDecoratedDescriptor(_obj, 'os', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'os'), _obj), _applyDecoratedDescriptor(_obj, 'osIcon', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'osIcon'), _obj)), _obj)));
});