define('travis/initializers/tracer', ['exports', 'travis/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Tracer = function () {
    function Tracer() {
      _classCallCheck(this, Tracer);

      this.requests = [];
    }

    _createClass(Tracer, [{
      key: 'enable',
      value: function enable() {
        window.localStorage['apiTrace'] = 'true';
      }
    }, {
      key: 'disable',
      value: function disable() {
        delete window.localStorage['apiTrace'];
      }
    }, {
      key: 'isEnabled',
      value: function isEnabled() {
        return window.localStorage['apiTrace'] === 'true';
      }
    }, {
      key: 'onRequest',
      value: function onRequest() {}
    }, {
      key: 'install',
      value: function install() {
        this.installXHR();
      }
    }, {
      key: 'installXHR',
      value: function installXHR() {
        var tracer = this;

        var proxied = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function (method, url, async, user, pass) {
          var self = this;

          if (url.startsWith(_environment.default.apiEndpoint)) {
            var req = {
              loading: true,
              error: false,
              url: url.substr(_environment.default.apiEndpoint.length),
              method: method,
              start: new Date()
            };

            tracer.requests.push(req);

            this.addEventListener('readystatechange', function () {
              if (self.readyState == 4) {
                req.status = self.status;
                req.duration = new Date() - req.start;
                req.loading = false;
                req.error = self.status < 200 || self.status >= 400;
                req.requestId = self.getResponseHeader('X-Request-ID');
                req.requestIdShort = req.requestId ? req.requestId.substr(0, 8) : null;

                tracer.onRequest(req);
              }
            }, false);
          }

          proxied.call.apply(proxied, [this].concat(Array.prototype.slice.call(arguments)));

          if (url.startsWith(_environment.default.apiEndpoint)) {
            this.setRequestHeader('Trace', 'true');
          }
        };
      }
    }, {
      key: 'bookmarklet',
      value: function bookmarklet() {
        if (this.isEnabled()) {
          this.disable();
        } else {
          this.enable();
        }
        window.location = window.location;
      }
    }]);

    return Tracer;
  }();

  function initialize(app) {
    if (typeof window !== 'undefined') {
      window.TravisTracer = new Tracer();
      if (window.TravisTracer.isEnabled()) {
        window.TravisTracer.install();
        _environment.default.featureFlags.tracer = true;
      }
      return window.TravisTracer;
    }
  }

  exports.default = {
    name: 'tracer',
    initialize: initialize
  };
});