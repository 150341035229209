define('travis/services/raven', ['exports', 'ember-cli-sentry/services/raven', 'travis/config/environment', 'ember-decorators/service'], function (exports, _raven, _environment, _service) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj, _init;

  exports.default = _raven.default.extend((_obj = { features: null,

    benignErrors: ['TransitionAborted', 'TaskInstance', 'UnrecognizedURLError', 'not found', 'returned a 403', 'returned a 404', 'operation failed', 'operation was aborted', 'needs-auth'],

    unhandledPromiseErrorMessage: '',

    captureException: function captureException() /* error */{
      this._super.apply(this, arguments);
    },
    logException: function logException(e) {
      // eslint-disable-next-line
      console.log('Caught an exception:', e);

      if (!this.ignoreError(e)) {
        this.captureException(e);
      }
    },
    captureMessage: function captureMessage() /* message */{
      return this._super.apply(this, arguments);
    },
    enableGlobalErrorCatching: function enableGlobalErrorCatching() {
      return this._super.apply(this, arguments);
    },
    ignoreError: function ignoreError(error) {
      if (!this.shouldReportError()) {
        return true;
      } else {
        var message = error.message;
        if (message) {
          return this.get('benignErrors').any(function (error) {
            return message.includes(error);
          });
        } else {
          return false;
        }
      }
    },
    callRaven: function callRaven() /* methodName, ...optional */{
      return this._super.apply(this, arguments);
    },
    shouldReportError: function shouldReportError() {
      // Sentry recommends only reporting a small subset of the actual
      // frontend errors. This can get *very* noisy otherwise.
      if (this.get('features.enterpriseVersion') || _environment.default.sentry.development) {
        return false;
      } else {
        var sampleRate = 10;
        return Math.random() * 100 <= sampleRate;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'features', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'features'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj)), _obj));
});