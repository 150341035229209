define('travis/routes/first-sync', ['exports', 'travis/config/environment', 'travis/routes/simple-layout'], function (exports, _environment, _simpleLayout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _simpleLayout.default.extend({
    activate: function activate() {
      var controller = void 0;
      controller = this.controllerFor('firstSync');
      controller.addObserver('isSyncing', this, this.isSyncingDidChange);
      this.isSyncingDidChange();
    },
    deactivate: function deactivate() {
      var controller = void 0;
      controller = this.controllerFor('firstSync');
      return controller.removeObserver('controller.isSyncing', this, this.isSyncingDidChange);
    },
    isSyncingDidChange: function isSyncingDidChange() {
      var controller = this.controllerFor('firstSync');
      if (!controller.get('isSyncing')) {
        return Ember.run.later(this, function () {
          return this.transitionTo('profile');
        }, _environment.default.timing.syncingPageRedirectionTime);
      }
    },


    actions: {
      redirectToGettingStarted: function redirectToGettingStarted() {
        // do nothing, we are showing first sync, so it's normal that there is
        // no owned repos
      }
    }
  });
});