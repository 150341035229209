define('travis/components/job-tabs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'div',
    classNames: ['travistab'],

    didInsertElement: function didInsertElement() {
      if (Ember.isEmpty(this.$('.travistab-nav--secondary').find('.active'))) {
        this.$('#tab_log').addClass('active');
      }
    }
  });
});