define('travis/controllers/loading', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    layoutName: Ember.computed({
      get: function get() {
        if (this._layoutName) {
          return 'layouts/' + this._layoutName;
        }
      },
      set: function set(key, value) {
        return this._layoutName = value;
      }
    })
  });
});