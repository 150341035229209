define('travis/components/x-tracer', ['exports', 'ember-decorators/object', 'travis/config/environment'], function (exports, _object, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj;

  exports.default = Ember.Component.extend((_obj = {
    tagName: 'div',
    panelIsOpen: false,
    requests: [],

    config: _environment.default,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (window.localStorage.TravisTracerIsOpen) {
        this.panelIsOpen = true;
      }

      TravisTracer.onRequest = function (req) {
        _this.get('requests').pushObject(req);
        _this.ensurePanelScrolledToBottom();
      };
    },
    toggleOpen: function toggleOpen() {
      this.toggleProperty('panelIsOpen');
      this.rememberPanelOpenState();
      this.ensurePanelScrolledToBottom();
    },
    ensurePanelScrolledToBottom: function ensurePanelScrolledToBottom() {
      Ember.run.next(function () {
        var panel = document.getElementById('tracer-panel');
        panel.scrollTop = panel.scrollHeight + 20;
      });
    },
    rememberPanelOpenState: function rememberPanelOpenState() {
      if (this.get('panelIsOpen')) {
        window.localStorage.TravisTracerIsOpen = 'true';
      } else {
        delete window.localStorage.TravisTracerIsOpen;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'toggleOpen', [_object.action], Object.getOwnPropertyDescriptor(_obj, 'toggleOpen'), _obj)), _obj));
});