define('travis/routes/account/repositories', ['exports', 'travis/routes/basic', 'travis/config/environment', 'ember-decorators/object'], function (exports, _basic, _environment, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = _basic.default.extend((_dec = (0, _object.computed)(), (_obj = {
    queryParams: {
      page: {
        refreshModel: true
      }
    },

    recordsPerPage: function recordsPerPage() {
      return _environment.default.pagination.profileReposPerPage;
    },
    model: function model(params) {
      var account = this.modelFor('account');
      // account is an Ember-Data model
      if (!account.error) {
        // TODO: Make perPage property configurable
        var offset = (params.page - 1) * this.get('recordsPerPage');
        return this.store.paginated('repo', {
          offset: offset,
          sort_by: 'name',
          limit: this.get('recordsPerPage'),
          custom: {
            owner: account.get('login'),
            type: 'byOwner'
          }
        }, { live: false });
      }
    },
    setupController: function setupController(controller, model) {
      var account = this.modelFor('account');
      if (!account.error) {
        controller.set('login', account.get('login'));
      }
      return this._super.apply(this, arguments);
    }
  }, (_applyDecoratedDescriptor(_obj, 'recordsPerPage', [_dec], Object.getOwnPropertyDescriptor(_obj, 'recordsPerPage'), _obj)), _obj)));
});