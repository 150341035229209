define('travis/routes/application', ['exports', 'travis/routes/basic', 'travis/config/environment', 'travis/mixins/build-favicon', 'ember-decorators/service', 'ember-keyboard-shortcuts/mixins/route'], function (exports, _basic, _environment, _buildFavicon, _service, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj, _init, _init2, _init3, _init4, _init5;

  exports.default = _basic.default.extend(_buildFavicon.default, _route.default, (_obj = { flashes: null,
    auth: null,
    featureFlags: null,
    repositories: null,
    features: null,

    needsAuth: false,

    init: function init() {
      var _this = this;

      this.get('auth').afterSignOut(function () {
        _this.afterSignOut();
      });
      return this._super.apply(this, arguments);
    },


    renderTemplate: function renderTemplate() {
      if (this.get('features.proVersion')) {
        Ember.$('body').addClass('pro');
      }
      return this._super.apply(this, arguments);
    },

    model: function model() {
      if (this.get('auth.signedIn')) {
        return this.get('featureFlags.fetchTask').perform();
      }
    },
    activate: function activate() {
      this.setupRepoSubscriptions();
    },


    // We send pusher updates through user channels now and this means that if a
    // user is not a collaborator of a repo or a user is not signed in, we need to
    // use repo channels for updates for each repo. This method ensures that a
    // visitor is subscribed to all of the public repos in the store as long as
    // they're not a collaborator. It also sets up an observer to subscribe to any
    // new repo that enters the store.
    setupRepoSubscriptions: function setupRepoSubscriptions() {
      var _this2 = this;

      this.get('store').filter('repo', null, function (repo) {
        return !repo.get('private') && !repo.get('isCurrentUserACollaborator');
      }, ['private', 'isCurrentUserACollaborator']).then(function (repos) {
        repos.forEach(function (repo) {
          return _this2.subscribeToRepo(repo);
        });
        repos.addArrayObserver(_this2, {
          willChange: 'reposWillChange',
          didChange: 'reposDidChange'
        });
      });
    },
    reposWillChange: function reposWillChange(array, start, removedCount, addedCount) {
      var _this3 = this;

      var removedRepos = array.slice(start, start + removedCount);
      return removedRepos.forEach(function (repo) {
        return _this3.unsubscribeFromRepo(repo);
      });
    },
    reposDidChange: function reposDidChange(array, start, removedCount, addedCount) {
      var _this4 = this;

      var addedRepos = array.slice(start, start + addedCount);
      return addedRepos.forEach(function (repo) {
        return _this4.subscribeToRepo(repo);
      });
    },


    unsubscribeFromRepo: function unsubscribeFromRepo(repo) {
      if (this.pusher) {
        this.pusher.unsubscribe('repo-' + repo.get('id'));
      }
    },

    subscribeToRepo: function subscribeToRepo(repo) {
      if (this.pusher) {
        this.pusher.subscribe('repo-' + repo.get('id'));
      }
    },

    title: function title(titleParts) {
      if (titleParts.length) {
        titleParts = titleParts.reverse();
        titleParts.push('Travis CI');
        return titleParts.join(' - ');
      } else {
        return _environment.default.defaultTitle;
      }
    },


    keyboardShortcuts: {
      'up': {
        action: 'disableTailing',
        preventDefault: false
      },
      'down': {
        action: 'disableTailing',
        preventDefault: false
      }
    },

    actions: {
      signIn: function signIn() {
        var authParams = this.modelFor('auth');
        this.get('auth').signIn(null, authParams);
        this.afterSignIn();
      },
      signOut: function signOut() {
        this.get('auth').signOut();
      },
      disableTailing: function disableTailing() {
        Travis.tailing.stop();
      },
      redirectToGettingStarted: function redirectToGettingStarted() {
        // keep as a no-op as this bubbles from other routes
      },
      error: function error(_error) {
        if (_error === 'needs-auth') {
          this.set('auth.redirected', true);
          var currentURL = new URL(window.location.href),
              routerURL = '' + currentURL.origin + this.get('router.url');

          return this.transitionTo('auth', { queryParams: { redirectUri: routerURL } });
        } else {
          return true;
        }
      },
      showRepositories: function showRepositories() {
        this.transitionTo('index');
      },
      viewSearchResults: function viewSearchResults(query) {
        this.transitionTo('search', query);
      },
      helpscoutTrigger: function helpscoutTrigger() {
        HS.beacon.open();
        return false;
      }
    },

    afterSignIn: function afterSignIn() {
      this.get('flashes').clear();
      var transition = this.get('auth.afterSignInTransition');
      if (transition) {
        this.set('auth.afterSignInTransition', null);
        return transition.retry();
      } else {
        return this.transitionTo('index');
      }
    },
    afterSignOut: function afterSignOut() {
      this.get('featureFlags').reset();
      this.set('repositories.accessible', []);
      this.setDefault();
      if (this.get('features.enterpriseVersion')) {
        return this.transitionTo('auth');
      }
      return this.transitionTo('index');
    }
  }, (_applyDecoratedDescriptor(_obj, 'flashes', [_service.service], (_init = Object.getOwnPropertyDescriptor(_obj, 'flashes'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'auth', [_service.service], (_init2 = Object.getOwnPropertyDescriptor(_obj, 'auth'), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'featureFlags', [_service.service], (_init3 = Object.getOwnPropertyDescriptor(_obj, 'featureFlags'), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'repositories', [_service.service], (_init4 = Object.getOwnPropertyDescriptor(_obj, 'repositories'), _init4 = _init4 ? _init4.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init4;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'features', [_service.service], (_init5 = Object.getOwnPropertyDescriptor(_obj, 'features'), _init5 = _init5 ? _init5.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init5;
    }
  }), _obj)), _obj));
});